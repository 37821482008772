import * as React from 'react'
import styled from '@emotion/styled'
import { navy, lightOrange2 } from 'shared/colors'
import Button from 'components/button'
import { tablet, mobile } from 'shared/media-queries'
import { showRequestDemoModal } from 'components/modal-manager'

const Container = styled.div`
	height: 160px;
	background-color: ${navy};
	justify-content: center;
	align-items: center;
	display: flex;
	${tablet} {
		flex-direction: column;
		height: 233px;
	}
`

const Text = styled.div`
	font-size: 26px;
	font-weight: bold;
	line-height: 1.46;
	color: white;
	margin-right: 20px;
	${tablet} {
		text-align: center;
		margin-left: 0;
		margin-bottom: 20px;
	}
	${mobile} {
		font-size: 22px;
		margin: 20px auto;
	}
`
const StyledBr = styled.br`
	display: none;
	${tablet} {
		display: unset;
	}
`
const FooterButton = styled(Button)`
	background-color: ${lightOrange2};
	padding: 0 20px;
	width: 196px;
	height: 44px;
`

const Footer: React.FC = () => {
	return (
		<Container>
			<Text>
				Unlock the full power of <StyledBr /> the BuildingConnected network.
			</Text>
			<FooterButton onClick={showRequestDemoModal}>
				Get a free trial
			</FooterButton>
		</Container>
	)
}

export default Footer
