import * as React from 'react'
// import _ from 'lodash'
import { useState } from 'react'
import styled from '@emotion/styled'
// import Papa from 'papaparse'
import Filters from '../components/filters'
import Results from '../components/results'
import { tablet } from 'shared/media-queries'
import { withFilters } from '../helpers'

// prettier-ignore
export interface StateValues {
	union: boolean;
	nonunion: boolean;
	wages: boolean;
	work: Array<string>
}

const Container = styled.div`
	justify-content: center;
	display: flex;
	padding-top: 51px;
	${tablet} {
		flex-direction: column;
		padding: 0;
	}
`

const Main: React.FC<{ data: Array<any>; ready: boolean }> = ({
	data,
	ready,
}) => {
	const [filters, updateFilters] = useState([])
	const filteredData = withFilters(filters, data)
	return (
		<Container>
			<Filters updateFilters={updateFilters} />
			<Results data={filteredData} ready={ready} />
		</Container>
	)
}

export default Main
