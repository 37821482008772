import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { grayText, gray20, black02 } from 'shared/colors'
import tradeList from '../trade-list'
import FilterIcon from '../img/ico-filter.svg'
import ArrowIcon from 'imgs/icons/carrot-arrow.svg'
import { laptop, tablet, mobile } from 'shared/media-queries'

// prettier-ignore
interface FiltersArray {
	filters: Array<string>;
}
// prettier-ignore
interface FiltersProps {
	updateFilters: (FiltersArray) => any
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	${tablet} {
		background-color: white;
		flex-direction: row;
		height: 100px;
		align-items: baseline;
		justify-content: space-around;
		border: 1px solid #d8d8d8;
		padding: 20px;
	}
	${tablet} {
		flex-direction: column;
		height: 160px;
	}
	${mobile} {
		height: ${(props: { isOpen: boolean }) =>
			props.isOpen ? '160px' : '50px'};
		padding: ${(props: { isOpen: boolean }) => (props.isOpen ? '33px' : '0px')};
	}
`

const Noninteractive = styled.div`
	display: flex;
`
const Interactive = styled.div`
	display: none;
	${mobile} {
		display: block;
		margin-top: -2px;
	}
`
const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	${mobile} {
		display: ${(props: { isOpen: boolean }) =>
			props.isOpen ? 'flex' : 'none'};
	}
`
const Input = styled(ReactSelect)`
	width: 250px;
	font-size: 16px;
	font-weight: 300;
	color: ${grayText};
	${laptop} {
		width: 220px;
	}
	${tablet} {
		width: 310px;
	}
`
const FilterToggle = styled.div`
	width: 26px;
	height: 26px;
	border-radius: 13px;
	background-color: #ebedf2;
	text-align: center;
	padding-top: 5.5px;
	padding-left: 2px;
	transform: ${(props: { isOpen: boolean }) =>
		props.isOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
`
const InputTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	color: ${black02};
`
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 1.29;
	letter-spacing: 1px;
	color: #757575;
	margin-bottom: 31px;
	${mobile} {
		width: 100%;
		justify-content: space-between;
		padding: ${(props: { isOpen: boolean }) => (props.isOpen ? '0px' : '33px')};
		margin-top: ${(props: { isOpen: boolean }) =>
			props.isOpen ? '15px' : '35px'};
	}
`
const IconContainer = styled.div`
	margin-right: 10px;
`
const Filters: React.FC<FiltersProps> = ({ updateFilters }) => {
	const [isOpen, toggleFilters] = useState(false)
	return (
		<Container isOpen={isOpen}>
			<TitleContainer isOpen={isOpen}>
				<Noninteractive>
					<IconContainer>
						<FilterIcon width="14px" height="15px" />
					</IconContainer>
					FILTERS
				</Noninteractive>
				<Interactive>
					<FilterToggle
						isOpen={isOpen}
						onClick={() => {
							toggleFilters(!isOpen)
						}}
					>
						<ArrowIcon fill="#979797" height="12px" width="12px" />
					</FilterToggle>
				</Interactive>
			</TitleContainer>
			<InputContainer isOpen={isOpen}>
				<InputTitle>Work Performed</InputTitle>
				<Input
					onChange={updates => {
						const _filters =
							updates && updates.length
								? updates.map(item => {
										return item.value
								  })
								: []
						updateFilters(_filters)
					}}
					options={tradeList.map(item => ({
						value: item,
						label: item,
					}))}
					isMulti
					placeholder="Type to search"
					styles={{
						option: (base, { isFocused }) => ({
							...base,
							fontSize: '16px',
							color: grayText,
							backgroundColor: isFocused ? gray20 : 'default',
						}),
						control: base => ({
							...base,
							minHeight: '34px',
							borderRadius: '1px',
						}),
					}}
					components={{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
					}}
				/>
			</InputContainer>
		</Container>
	)
}

export default Filters
