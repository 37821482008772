import * as React from 'react'
import styled from '@emotion/styled'
import { black02 } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const Container = styled.div`
	height: 100px;
	margin-top: 15px;
	text-align: center;
`
const PaginationContainer = styled.div`
	margin: 30px auto;
	color: ${black02};
	display: flex;
	font-weight: bold;
	justify-content: space-evenly;
	width: 515px;
	${mobile} {
		width: 310px;
	}
`

const Divider = styled.div`
	height: 1px;
	width: 100%;
	border: solid 1px #d9d9d9;
`
const Label = styled.div`
	opacity: 0.5;
	padding-top: 5px;
	margin: 0 15px;
`
// prettier-ignore
interface ItemContainerProps {
  num: string;
}

const ItemContainer = styled.div<ItemContainerProps>`
	width: 39px;
	height: 39px;
	background-color: ${props => (props.num === '1' ? '#efefef' : 'none')};
	opacity: ${props => (props.num === '1' ? '0.5' : '0.25')};
	border-radius: 19.3px;
	font-weight: bold;
	padding-top: 5px;
`

const Item: React.FC<{ n: string }> = ({ n }) => (
	<ItemContainer num={n}>{n}</ItemContainer>
)

const getPagesArray = max => {
	if (max === 6)
		return [1, 2, 3, 4, 5, 6].map(num => <Item n={num.toString()} key={num} />)
	if (max > 6)
		return [1, 2, 3, 4, 5, '...', max].map(num => (
			<Item n={num.toString()} key={num} />
		))
	const a = Array.from(Array(max), (_, x) => x + 1)
	return a.map(num => <Item n={num.toString()} key={num} />)
}

// prettier-ignore
interface Props {
	itemCount: number;
	itemsPerPage: number;
}

const PageSelector: React.FC<Props> = ({ itemCount, itemsPerPage }) => {
	const pageCount =
		itemCount < itemsPerPage ? 1 : Math.round(itemCount / itemsPerPage)
	const pages = getPagesArray(pageCount)
	return (
		<Container>
			<Divider />
			{pageCount > 1 && (
				<PaginationContainer>
					<Label>Previous</Label>
					{pages}
					<Label>Next</Label>
				</PaginationContainer>
			)}
		</Container>
	)
}

export default PageSelector
