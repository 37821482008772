import * as React from 'react'
import styled from '@emotion/styled'
import BuildingIcon from '../img/ico-buildings.svg'
import PlusIcon from 'icons/plus.svg'
import ArrowIcon from 'imgs/icons/carrot-arrow.svg'
import { black02, lightGray, gray65 } from 'shared/colors'
import TopCompany from './top-company'
import { laptop, mobile } from 'shared/media-queries'
import { Company } from '../data-model'
import { showRequestDemoModal } from 'components/modal-manager'

const Container = styled.div`
	height: 116px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #e5e5e5;
	background-color: #ffffff;
	justify-content: space-between;
	align-items: center;
	display: flex;
	margin: 8px;
	padding: 15px;
	${mobile} {
		flex-direction: column;
		height: 165px;
	}
`
const LeftSide = styled.div`
	display: flex;
	${mobile} {
		width: 100%;
	}
`
const RightSide = styled.div`
	display: flex;
	flex: 0 0 350px;
	${laptop} {
		flex: 0 0 240px;
	}
	${mobile} {
		display: block;
		flex: unset;
		width: 100%;
	}
`
const RightSideContent = styled.div`
	display: flex;
	${laptop} {
		flex-direction: column-reverse;
		justify-content: space-between;
	}
`
const RightSideButtons = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 12px;
	justify-content: space-between;
	${mobile} {
		margin: 0;
	}
`
const ContactContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: 500;
	color: #191919;
	${laptop} {
		justify-content: space-between;
		font-size: 14px;
	}
	${mobile} {
		display: none;
	}
`
const CompanyName = styled.div`
	font-size: 21px;
	font-weight: 500;
	color: ${black02};
	${laptop} {
		font-size: 16px;
	}
`
const MainDetail = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
const LocationName = styled.div`
	font-size: 16px;
	font-weight: 300;
	color: ${black02};
	${laptop} {
		font-size: 12px;
	}
	${mobile} {
		margin-left: 1px;
	}
`
const CompanyInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: -5px;
	${mobile} {
		margin-top: 0;
	}
`
const Image = styled.div`
	width: 86px;
	height: 86px;
	background-color: #ebedf2;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-right: 15px;
`
const VerticalDivider = styled.div`
	width: 1px;
	height: 80px;
	border: solid 1px #cccccc;
	margin: 0 20px;
	${mobile} {
		display: none;
	}
`
const InviteClear = styled.div`
	width: 131px;
	height: 34px;
	border-radius: 2px;
	border: solid 1px ${lightGray};
	color: ${gray65};
	font-size: 16px;
	font-weight: 500;
	line-height: 34px;
	justify-content: center;
	text-align: center;
	display: flex;
	cursor: pointer;
	${mobile} {
		width: 100%;
		margin-top: 15px;
	}
`
const ViewContacts = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: ${gray65};
	display: flex;
	${laptop} {
		display: none;
	}
`
const IconWrapper = styled.div`
	transform: rotate(90deg);
	width: 16px;
	height: 16px;
	text-align: end;
`

const PlusWrapper = styled.div`
	width: 11px;
	height: 11px;
	margin-right: 5px;
`

interface Props {
	company: Company
}

const RowItem: React.FC<Props> = ({ company }) => {
	const {
		companyName,
		city,
		contactFirstName,
		contactLastName,
		state,
	} = company
	return (
		<Container>
			<LeftSide>
				<Image>
					<BuildingIcon width="57px" height="54px" />
				</Image>
				<MainDetail>
					<CompanyInfo>
						<CompanyName>{companyName}</CompanyName>
						<LocationName>
							{city}, {state}
						</LocationName>
					</CompanyInfo>
					<TopCompany />
				</MainDetail>
			</LeftSide>
			<RightSide>
				<VerticalDivider />
				<RightSideContent>
					<RightSideButtons>
						<InviteClear onClick={showRequestDemoModal}>
							<PlusWrapper>
								<PlusIcon height="11px" width="11px" fill="#757575" />
							</PlusWrapper>
							Invite to bid
						</InviteClear>
						<ViewContacts>
							View all contacts
							<IconWrapper>
								<ArrowIcon height="7px" width="12px" />
							</IconWrapper>
						</ViewContacts>
					</RightSideButtons>
					<ContactContainer>
						{contactFirstName} {contactLastName}
					</ContactContainer>
				</RightSideContent>
			</RightSide>
		</Container>
	)
}

export default RowItem
