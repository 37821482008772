export default [
	'Abatement',
	'AC Dike',
	'Access Doors',
	'Access Flooring',
	'Accessories',
	'Acoustical Ceilings',
	'Aerial Photos',
	'Aggregate',
	'Air Barriers',
	'Air Curtains',
	'Air Flow Stations',
	'Air Handling Units',
	'Aluminum Doors & Frames',
	'Appliances',
	'Architectural Precast',
	'Architectural Woodwork',
	'Architecture',
	'Asbestos',
	'Asphalt',
	'Athletic Equipment',
	'Athletic Surfacing',
	'Audio Visual',
	'Audio Visual Equipment',
	'Auger Cast Piles',
	'Auto Operating Doors',
	'Automatic Temperature Controls',
	'Automation Starter',
	'Awnings',
	'Backflow Apparatus',
	'Banquettes',
	'Bathroom Accessories',
	'Belts and Sheaves',
	'Bicycle Racks',
	'BIM Design',
	'Bird Control Devices',
	'Blasting',
	'Boiler Stack & Generators',
	'Boilers - Copper Fin',
	'Boilers - Steel Tube',
	'Boring',
	'Bridge Bearings & Pads',
	'Bridge Repairs',
	'Bridges',
	'BTU Meter',
	'Building Insulation',
	'Building Management Systems',
	'Built-Up Roofing',
	'Canopy',
	'Carpet',
	'Casework',
	'Cast Stone',
	'Cathodic Protection',
	'Caulking',
	'Cellular Concrete',
	'Ceramic Tile',
	'Chillers',
	'Chutes & Collectors',
	'Civil Engineering',
	'Clean Rooms',
	'Clearing & Grubbing',
	'Coiling Doors',
	'Cold Rooms',
	'Commercial Laundry Equipment',
	'Commissioning',
	'Composite Panels',
	'Compressed Air And Gas',
	'Concrete',
	'Concrete Accessories',
	'Concrete Finishing',
	'Concrete Flooring',
	'Concrete Formwork',
	'Concrete Gypsum Board',
	'Concrete Pumping',
	'Concrete Restoration',
	'Concrete Slab Moisture Testing',
	'Concrete Suppliers',
	'Construction Cleaning',
	'Consultants',
	'Control Valves',
	'Controlled Environment Rooms',
	'Conveying Systems',
	'Conveyors',
	'Cooling & Heating Coils',
	'Cooling Towers',
	'Core Drilling',
	'Countertops',
	'Cranes',
	'Cubicle Curtains',
	'Curbing',
	'Cured In Place Pipe',
	'Curtain Wall',
	'Data & Telecommunications',
	'Deck Grooving',
	'Deep Foundation Testing',
	'Dehumidifiers',
	'Demolition',
	'Demountable Partitions',
	'Detention Systems',
	'Dewatering',
	'Display Cases',
	'Doors',
	'Drainage',
	'Draperies',
	'Drilling',
	'Drug Testing',
	'Drywall',
	'Drywall & Framing',
	'Duct Cleaning',
	'Duct Heaters',
	'Duct Insulation',
	'Dumbwaiters',
	'Dumpsters',
	'Earth Retention',
	'Earthwork',
	'EIFS',
	'Electric Vehicle Chargers',
	'Electrical',
	'Electrical Equipment',
	'Electrical Panels',
	'Elevators',
	'Engineer',
	'Environmental',
	'Epoxy Flooring',
	'Equipment',
	'Equipment Rental',
	'Erosion Control',
	'Erosion Control - Hydroseed',
	'Escalators',
	'Excavation',
	'Expansion Control Systems',
	'Exterior Sun Control Devices',
	'Fabric Wrapped Wall Panels',
	'Fall Arrest Systems',
	'Fall Protection',
	'Fans',
	'Fastners',
	'Fences',
	'Filters',
	'Final Cleaning',
	'Finished Carpentry',
	'Fire Alarm',
	'Fire Dampers',
	'Fire Extinguishers & Cabinets',
	'Fire Protection',
	'Fireplaces',
	'Fireproofing',
	'Firestopping',
	'Fittings',
	'Fixed Audience Seating',
	'Fixtures',
	'Flagpoles',
	'Flashings',
	'Flatwork',
	'Flood Mitigation',
	'Flow Control Valves',
	'Flow Instruments',
	'Flow Meters',
	'Foam Insulation',
	'Food Service Equipment',
	'Foundations',
	'Fountains',
	'Frames',
	'Freeway Signage',
	'FRP',
	'Fuel Storage Pumps',
	'Fuel Storage Tanks',
	'Fuel Systems Piping',
	'Fume Hoods',
	'Furnishings',
	'Furniture',
	'Gabion & Reno Baskets',
	'Garage Door',
	'Gas Testing & Certification',
	'Gas Vapor Barrier Venting',
	'Gates',
	'Gauges & Instruments',
	'General Contracting',
	'Generators',
	'Geo Thermal',
	'Geotechnical Engineering',
	'Glass & Glazing',
	'Grading',
	'Granite',
	'Grating',
	'Green Roofs',
	'Greenhouse',
	'Grinding',
	'Grout',
	'Guardrails',
	'Gutters',
	'Gymnasium Equipment',
	'Gypcrete',
	'Hardware',
	'Hauling',
	'Hazardous Material Handling',
	'Healthcare Equipment',
	'Heat Exchangers',
	'Heat Pumps',
	'Heat Tracing',
	'Heavy Timber Construction',
	'HEPA Filters',
	'Highway Barriers',
	'Highway Barriers - Cast-in-place',
	'Hoists',
	'Hollow Metal Doors',
	'Hoods & Ventilation Equipment',
	'Humidifiers',
	'HVAC',
	'HVAC Equipment',
	'Hydrodemolition',
	'Import Fill',
	'Inspections',
	'Intumescent Paint',
	'Irrigation',
	'Jacking',
	'Joint Sealants',
	'Joint Trench',
	'Kitchen Equipment',
	'Kitchen Hood',
	'Lab Casework',
	'Lab Equipment',
	'Ladders',
	'Landscape',
	'Lath & Plaster',
	'Lead Abatement',
	'Lead Doors',
	'Library Equipment',
	'Lifts',
	'Lighting',
	'Lightning Protection',
	'Limestone',
	'Liners',
	'Loading Dock Equipment',
	'Lockers',
	'Louvers',
	'Lumber',
	'Man Material Hoists',
	'Manufactured Casework',
	'Marble',
	'Marine Work',
	'Masonry',
	'Masonry Restoration',
	'Materials Testing',
	'Mats',
	'Mechanical',
	'Medical Equipment',
	'Medical Gas',
	'Membrane Roofing',
	'Metal Buildings',
	'Metal Decking',
	'Metal Fabrications',
	'Metal Framing',
	'Metal Joists',
	'Metal Materials',
	'Metal Panels',
	'Metal Roofing',
	'Metal Shelving',
	'Metal Siding',
	'Metal Stairs',
	'Microsurfacing',
	'Millwork',
	'Misc. Metals',
	'MSE Wall',
	'Murals',
	'Nurse Call',
	'Operable Partitions',
	'Ornamental Metals',
	'Oval Duct',
	'Overhead Coiling Doors',
	'Overhead Doors',
	'Painting',
	'Parking Control Equipment',
	'Partitions',
	'Paving',
	'Paving Oil & Fabric',
	'Pedestrian Control Equipment',
	'Perforated Sheets',
	'Pest Control',
	'Photovoltaic',
	'Piles & Caissons',
	'Pipe Jacking',
	'Piping',
	'Piping Insulation',
	'Planrooms',
	'Plaster',
	'Plaster Restoration',
	'Plastic Laminates',
	'Playground Equipment',
	'Plumbing',
	'Plumbing Fixtures',
	'Pneumatic Tube',
	'Polished Concrete',
	'Polymer Overlay',
	'Pools',
	'Post-Tensioned Concrete',
	'Postal Specialties',
	'Pre-Engineered Structures',
	'Pre-Fab Buildings',
	'Precast Girders',
	'Precast Specialties',
	'Printing',
	'Process Piping',
	'Projection Screens',
	'Public Information',
	'Pumps',
	'Radiation Protection',
	'Railings',
	'Railroads',
	'Rainscreen',
	'Ready Mix Supplier',
	'Rebar',
	'Refrigeration Systems',
	'Reinforcing Steel',
	'Resilient Flooring',
	'Resilient Matting',
	'Resinous Floors',
	'Retaining Walls',
	'Revolving Doors',
	'RF Shielding',
	'Rigging',
	'Road Signs',
	'Roof Hatch',
	'Roof Tiles',
	'Roofing',
	'Rotomilling',
	'Rough Carpentry',
	'Rubber Flooring',
	'Rumble Strips',
	'S/S Ductwork',
	'Safety Specialties',
	'Sand Blasting',
	'Sanitary Sewerage',
	'Saunas',
	'Saw Cutting',
	'Scaffolding',
	'Scales',
	'Scanning Service',
	'Scoreboards',
	'Seal Coating',
	'Seating',
	'Security Guards',
	'Security Systems',
	'Seismic Restraints',
	'Selective Demolition',
	'Sewerage',
	'Sheet Metal',
	'Sheet Piling',
	'Shingle Roofing',
	'Shoring',
	'Shotcrete',
	'Shutters',
	'Siding',
	'Signage',
	'SIPs',
	'Site Concrete',
	'Site Demolition',
	'Site Furnishings',
	'Site Remediation',
	'Site Utilities',
	'Site Work',
	'Skylights',
	'Slate Roofing',
	'Slip Lining',
	'Slurry Walls',
	'Soil Treatment',
	'Solar Panels',
	'Solid Surface Countertops',
	'Sound & Vibration Control',
	'Special Construction',
	'Special Doors',
	'Specialties',
	'Sprayed Insulation',
	'Startup',
	'Steel Doors',
	'Steel Girders',
	'Steel Trusses',
	'Steel Windows',
	'Stone',
	'Stone Floors',
	'Stone Veneer',
	'Storage Shelving',
	'Storage Tanks',
	'Storefronts',
	'Striping',
	'Structural Engineering',
	'Structural Insulated Panels',
	'Structural Precast',
	'Structural Roof Panel Systems',
	'Structural Steel',
	'Structural Steel - Bridge',
	'Structural Steel Erection',
	'Stucco',
	'Submersible Pumps',
	'Surveying',
	'Swimming Pools',
	'Switchgear',
	'SWPPP',
	'Synthetic Fields',
	'Synthetic Track',
	'Temp Facilities',
	'Temporary Barricades',
	'Temporary Fencing',
	'Temporary Interior Protection',
	'Temporary Power',
	'Tensioned Fabric Structures',
	'Terrazzo',
	'Testing & Balancing',
	'Testing Engineers',
	'Testing Laboratory Services',
	'Theater Equipment',
	'Theatrical Lighting',
	'Tile',
	'Tilt-Up Precast Concrete',
	'Time Lapse Camera',
	'Tinted Glass',
	'Toilet Partitions',
	'Tower Cranes',
	'Traffic Control',
	'Traffic Signals',
	'Trailers',
	'Translucent Walls',
	'Trees',
	'Trench Plates',
	'Trucking',
	'Tunneling',
	'Underground Piping',
	'Underground Utilities',
	'Underpinning',
	'Unistrut System',
	'Unit Pavers',
	'Vacuum Pumps',
	'Valves',
	'Variable Speed Drives',
	'Vault Equipment',
	'VCT',
	'Vehicle Lifts',
	'Vents',
	'Visual Display Boards',
	'Wall & Corner Guards',
	'Wall Coverings',
	'Wall Panels',
	'Wardrobe Closet Specialties',
	'Waste Compactors',
	'Wastewater Equipment',
	'Water Treatment',
	'Water Vapor Emission Control System',
	'Water Wells',
	'Waterproofing',
	'Welding',
	'Wheelchair Lifts',
	'Whiteboards',
	'Window Coverings',
	'Window Film',
	'Window Treatment',
	'Window Washing Systems',
	'Windows',
	'Wire Mesh Partitions',
	'Wood Decking',
	'Wood Flooring',
	'Wood Framing',
	'Wood Trusses',
	'Wood Windows',
]
