import * as React from 'react'
import styled from '@emotion/styled'
import { navy, grayText } from 'shared/colors'

const Container = styled.div`
	width: 958px;
	height: 355px;
	border-radius: 3px;
	background-color: white;
	text-align: center;
	padding-top: 135px;
`

const Title = styled.div`
	font-size: 38px;
	font-weight: bold;
	color: ${navy};
`
const Subtitle = styled.div`
	font-size: 22px;
	color: ${grayText};
`

const NoResults: React.FC = () => {
	return (
		<Container>
			<Title>No matches found</Title>
			<Subtitle>Please update your filters and try again!</Subtitle>
		</Container>
	)
}

export default NoResults
