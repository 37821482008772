import * as React from 'react'
import styled from '@emotion/styled'
import { lightBlue } from 'shared/colors'
import StarIcon from '../img/ico-star.svg'
import { laptop, mobile } from 'shared/media-queries'

const Container = styled.div`
	width: 135px;
	height: 24px;
	border-radius: 2px;
	border: solid 1px ${lightBlue};
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.5;
	text-align: center;
	color: ${lightBlue};
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-right: 20px;
	${laptop} {
		font-size: 12px;
		letter-spacing: 0.43px;
		width: 120px;
	}
	${mobile} {
		margin-top: 5px;
	}
`

const TopCompany: React.FC = () => {
	return (
		<Container>
			<StarIcon height="12px" width="12px" />
			TOP COMPANY
		</Container>
	)
}

export default TopCompany
