import _ from 'lodash'
import { Company } from './data-model'

export const dedupe = (data: Array<Company>) => _.uniqBy(data, 'companyId')

export const withFilters = (items: Array<any>, data: Array<Company>) => {
	if (items.length === 0) return dedupe(data)
	const filtered = data.filter((company: Company) => {
		let pass = false
		items.forEach(item => {
			if (company.trade === item) {
				pass = true
			}
		})
		return pass
	})
	return dedupe(filtered)
}
