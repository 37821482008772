import * as React from 'react'
import { useState, useEffect } from 'react'
import Layout from 'components/layout'
import Header from './components/header'
import Footer from './components/footer'
import Main from './main'
import styled from 'styled-components'
import Papa from 'papaparse'
import LeadMagnetData from './lead-magnet-data-feb14'
import { dedupe } from './helpers'

const AltGrayBackground = styled.div`
	background-color: #fcfcfc;
`

const LeadMagnet: React.FC = () => {
	const [rows, setRows] = useState([])
	const [ready, toggleReady] = useState(false)

	const getData = async () => {
		Papa.parse(LeadMagnetData, {
			header: true,
			complete: results => {
				setRows(results.data)
				toggleReady(true)
			},
		})
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<Layout
			title="Subcontractor Network | Send Invitations to Bid | BuildingConnected"
			description="BuildingConnected helps bid coordinators and estimators easily find and qualify subcontractors, send invitations to bid, and win more work"
			hideNav
		>
			<Header companyCount={dedupe(rows).length} ready={ready} />
			<AltGrayBackground>
				<Main data={rows} ready={ready} />
			</AltGrayBackground>
			<Footer />
		</Layout>
	)
}

export default LeadMagnet
