import * as React from 'react'
import styled from '@emotion/styled'
import { navy } from 'shared/colors'
import backgroundImageURI from '../img/background'
import H1 from 'components/h1'
import { tablet, mobile } from 'shared/media-queries'

const Container = styled.div`
	height: 240px;
	background: url(${backgroundImageURI}) no-repeat top -80px center;
	background-color: ${navy};
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	${mobile} {
		height: 180px;
	}
`

const Title = styled(H1)`
	color: white;
	text-align: center;
	${mobile} {
		max-width: 333px;
	}
`

const Subtitle = styled.h2`
	color: white;
	text-align: center;
	${tablet} {
		font-size: 22px;
	}
	${mobile} {
		font-size: 16px;
	}
`

const Header: React.FC<{ companyCount: string; ready: boolean }> = ({
	companyCount,
	ready,
}) => {
	return (
		<Container>
			<Title>
				{ready && `${companyCount} subcontractors in Salt Lake City, Utah`}
			</Title>
			<Subtitle>
				Find the right subcontractors on the largest construction network
			</Subtitle>
		</Container>
	)
}

export default Header
