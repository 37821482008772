import * as React from 'react'
import styled from 'styled-components'
import { navy, grayText, cyan, lightOrange2 } from 'shared/colors'
import { mobile, breakpoints } from 'shared/media-queries'
import { BulletList, Button } from 'views/components'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { showRequestDemoModal } from 'components/modal-manager'

const Root = styled.div`
	height: 410px;
	justify-content: center;
	display: flex;
	max-width: 960px;
	${mobile} {
		width: 100%;
		height: 620px;
	}
`
const Container = styled.div`
	height: 440px;
	width: 620px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #e5e5e5;
	background-color: #ffffff;
	background: white;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	${mobile} {
		width: 300px;
		height: 726px;
		justify-content: flex-start;
	}
`

const Bottom = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	${mobile} {
		flex-direction: column;
		justify-content: center;
	}
`

const BulletDiv = styled.div`
	width: 220px;
	display: flex;
	justify-content: space-around;
	height: 250px;
	flex-direction: column;
	${mobile} {
		width: 260px;
		height: 448px;
		margin: 0 auto;
	}
`

const List = styled.div`
	font-size: 18px;
	font-weight: 300;
	line-height: 1.22;
	color: ${grayText};
`

const Title = styled.div`
	font-size: 30px;
	font-weight: bold;
	line-height: 1.11;
	color: ${navy};
	text-align: center;
	width: 455px;
	${mobile} {
		display: none;
	}
`

const MobileTitle = styled(Title)`
	display: none;
	${mobile} {
		font-size: 32px;
		max-width: 100%;
		display: inherit;
	}
`
const InTouchButton = styled(Button)`
	background-color: ${lightOrange2};
	width: 100%;
	height: 44px;
	border-radius: 2px;
	line-height: 44px;
	font-size: 18px;
	font-weight: 500;
	display: flex;
	justify-content: space-around;
	${mobile} {
		width: 260px;
		margin: 0 auto;
	}
`

const ImageContainer = styled.div`
	width: 282px;
	height: 276px;
	${mobile} {
		width: 300px;
		height: 278px;
	}
`

const SubImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(relativePath: { eq: "lead-magnet/img/desktop.png" }) {
					childImageSharp {
						fixed(height: 276) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				mobile: file(relativePath: { eq: "lead-magnet/img/mobile.png" }) {
					childImageSharp {
						fixed(height: 278) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	const sources = [
		{
			...data.desktop.childImageSharp.fixed,
			media: `(min-width: ${breakpoints.tablet}px)`,
		},
		data.mobile.childImageSharp.fixed,
	]

	return (
		<ImageContainer>
			<GatsbyImage
				fixed={sources}
				alt="Subs Image"
				style={{ width: '99%', height: '100%' }}
			/>
		</ImageContainer>
	)
}

const Bullets: React.FC<{ items: string[] }> = ({ items }) => (
	<List>
		<BulletList
			dotColor={cyan}
			items={items}
			additionalLiCss={{
				'font-size': '15px',
				'line-height': '1.38',
				'margin-top': '10px',
				[mobile]: {
					'font-size': '18px',
				},
			}}
		/>
	</List>
)

const SubscribeCTA: React.FC = () => {
	return (
		<Root>
			<Container>
				<Title>Discover the right subcontractor for any project.</Title>
				<Bottom>
					<SubImage />
					<BulletDiv>
						<MobileTitle>
							Discover the right subcontractor for any project.
						</MobileTitle>
						<Bullets
							items={[
								'Easily find new subcontractors with search filters like trade, location and more',
								'Save time with personalized, relevant results',
								'See the most up-to-date and accurate contact information',
							]}
						/>
						<InTouchButton
							onClick={showRequestDemoModal}
							label="Get in touch"
						/>
					</BulletDiv>
				</Bottom>
			</Container>
		</Root>
	)
}

export default SubscribeCTA
