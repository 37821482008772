import * as React from 'react'
import styled from '@emotion/styled'
import { lightOrange2 } from 'shared/colors'
import Button from 'components/button'
import RowItem from './row-item'
import PlusIcon from 'icons/plus.svg'
import PageSelector from './page-selector'
import SubscribeCTA from './subscribe-modal'
import { laptop, tablet, mobile } from 'shared/media-queries'
import { Company } from '../data-model'
import NoResults from './no-results'
import { showRequestDemoModal } from 'components/modal-manager'
import blursedDesktop from '../img/blur-desktop'
import blursedLaptop from '../img/blur-laptop'

const Container = styled.div`
	flex: 0 0 960px;
	margin-left: 40px;
	${laptop} {
		flex: 0 0 700px;
		margin-left: 10px;
	}
	${tablet} {
		padding: 20px;
		margin-left: 0;
	}
`

const CTA = styled.div`
	position: relative;
	min-height: 535px;
	${mobile} {
		margin: -20px;
		min-height: 800px;
	}
`
const CTAContainer = styled.div`
	height: 530px;
	position: absolute;
	z-index: 1;
	width: 100%;
	padding-top: 10px;
	${mobile} {
		padding-top: 42px;
	}
`
const ResultsHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 21px;
	padding: 0 5px;
	max-width: 975px;
	${laptop} {
		margin-bottom: 10px;
	}
`
const InviteButton = styled(Button)`
	background-color: ${lightOrange2};
	min-width: 238px;
	height: 34px;
	border-radius: 2px;
	line-height: 34px;
	font-size: 15px;
	font-weight: 500;
	display: flex;
	justify-content: space-around;
	${mobile} {
		display: none;
	}
`

const MobileInviteButton = styled(InviteButton)`
	display: none;
	${mobile} {
		display: flex;
		font-size: 12px;
		min-width: 111px;
		align-items: baseline;
	}
`

const BlursedRowItem = styled.div`
	height: 120px;
	margin: 8px;
	background: url(${blursedDesktop}) no-repeat center;
	${laptop} {
		height: 124px;
		background: url(${blursedLaptop}) no-repeat center;
	}
	${mobile} {
		flex-direction: column;
		height: 165px;
	}
`
const SearchResults = styled.div`
	display: flex;
	flex-direction: column;
`
const PlusWrapper = styled.div`
	width: 11px;
	height: 11px;
	margin-right: 5px;
`
// prettier-ignore
interface ResultsProps {
	data: Array<Company>;
	ready: boolean;
}

const shouldShowCTA = resultCount => resultCount > 0

const Results: React.FC<ResultsProps> = ({ data, ready }) => {
	const companyCount = data.length
	const amountToShow = 3 || data.length
	const itemsPerPage = 6

	// max rows to show of both blurred and not blurred
	const maxRowsIncludingBlurred = companyCount > 7 ? itemsPerPage : companyCount
	return (
		<Container>
			<ResultsHeader>
				{companyCount > 0 && (
					<InviteButton onClick={showRequestDemoModal}>
						<PlusWrapper>
							<PlusIcon height="11px" width="11px" fill="white" />{' '}
						</PlusWrapper>
						{`Invite ${companyCount} companies to bid`}
					</InviteButton>
				)}
				<MobileInviteButton onClick={showRequestDemoModal}>
					<PlusWrapper>
						<PlusIcon height="9px" width="9px" fill="white" />
					</PlusWrapper>
					Invite all
				</MobileInviteButton>
			</ResultsHeader>
			<SearchResults>
				{data.slice(0, amountToShow).map(company => {
					return <RowItem company={company} key={company.companyId} />
				})}
			</SearchResults>
			{shouldShowCTA(companyCount) && (
				<CTA>
					<CTAContainer>
						<SubscribeCTA />
					</CTAContainer>
					<div>
						{data.slice(amountToShow, maxRowsIncludingBlurred).map(company => {
							return <BlursedRowItem key={company.companyId} />
						})}
					</div>
				</CTA>
			)}
			{companyCount === 0 && ready && <NoResults />}
			{companyCount > 0 && (
				<PageSelector itemCount={companyCount} itemsPerPage={itemsPerPage} />
			)}
		</Container>
	)
}

export default Results
